import React from "react";
import { string, number, bool, shape, any, object, array } from "prop-types";
import { navigate } from "gatsby";
import { Col, Typography } from "antd";
import Heading from "../Base/HeadingBuilder";
import CtaSecondary from "../../components/Buttons/secondary-cta";
import { viewports, colors } from "../../style-vars";

const { Title } = Typography;
const { mdOrSmaller } = viewports;

const ArticleCard = (props) => {
  const {
    title,
    publishedDate,
    snippet,
    category,
    sizing,
    headerImg,
    linkTo,
    hideSnippet,
    hideLink,
    index,
    needOffset,
    imageHeight,
  } = props;
  const sizingType = sizing === 1 ? 8 : 12;
  const goTo = () => {
    if (hideLink) {
      navigate(linkTo);
    }
  };
  return (
    <Col lg={sizingType} sm={24}>
      {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events */}
      <div
        className={`articles-v2-card ${
          needOffset.length > 0 && needOffset.includes(index)
            ? "lg:mt-[64px]"
            : "0"
        } ${hideLink && "cursor-pointer"}`}
        onClick={goTo}
      >
        <div className="articles-v2-card__head">
          <img src={headerImg} className={imageHeight} alt="" loading="lazy" />
          {category.length > 0 ? (
            <span className="articles-v2-card__chip">{category}</span>
          ) : null}
        </div>
        <span className="articles-v2-card__date">{publishedDate}</span>
        <Heading level={3} injectionType={4}>
          {title}
        </Heading>
        {!hideSnippet && <p>{snippet && snippet}</p>}
        {!hideLink && <CtaSecondary ctaTitle="Read More" target={linkTo} />}
      </div>
    </Col>
  );
};

ArticleCard.propTypes = {
  title: string,
  publishedDate: string,
  snippet: string,
  category: string,
  sizing: number,
  headerImg: string,
  linkTo: string,
  imageHeight: string,
  hideSnippet: bool,
  hideLink: bool,
  index: number,
  needOffset: array,
};
ArticleCard.defaultProps = {
  title: "",
  publishedDate: "",
  snippet: "",
  category: "",
  sizing: 1,
  headerImg: "",
  imageHeight: "h-[235px]",
  hideSnippet: false,
  hideLink: false,
  linkTo: "",
  index: null,
  needOffset: [],
};

export default ArticleCard;
